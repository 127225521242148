@import url(https://fonts.googleapis.com/css2?family=Anonymous+Pro:wght@400;700&display=swap);
html,
body,
#root {
  height: 100%;
}

body {
  background-color: var(--dark200);
  /* background-image: repeating-linear-gradient(
    to right,
    var(--light100),
    var(--light100) 1px,
    transparent 1px,
    transparent
  );
  background-size: 4rem; */
}

body::-webkit-scrollbar {
  width: 0.75rem;
}

body::-webkit-scrollbar-thumb {
  background-color: var(--dark-accent200);
  border: 3px solid var(--dark100);
}

body::-webkit-scrollbar-track {
  background-color: var(--dark100);
}

img {
  max-width: 100%;
  display: inline-block;
}

.content-container {
  margin: 0 auto;
  max-width: 65rem;
  padding: 1rem 4.5rem;
}

.large-spacing > * + * {
  margin-top: 3rem;
}

.medium-spacing > * + * {
  margin-top: 1.5rem;
}

.small-spacing > * + * {
  margin-top: 1rem;
}

.xsmall-spacing > * + * {
  margin-top: 0.5rem;
}

[data-theme='mk'] {
  /* Fonts */
  --primary-font: 'Anonymous Pro', monospace;
  --header-font: 'Oxygen', sans-serif;
  --cursive-text: 'Shadows Into Light', cursive;
  /* Radius */
  --large-radius: 8px;
  --small-radius: 2px;
  /* Primary Text Colors */
  --primary-text-color: var(--light100);
  --secondary-text-color: var(--primary-brand100);
  /* Primary and Secondary Colors */
  --primary-color: var(--primary-brand300);
  --secondary-color: var(--light400);
  /* Hover */
  --hover-color: var(--primary-brand500);
  --hover-text-color: var(--white);
  --hover-outlined-bg-color: var(--light100);
  /* Focus */
  --focus-color: var(--primary-brand500);
  --focus-text-color: var(--white);
  --focus-outlined-bg-color: var(--light100);
  /* Active */
  --active-color: var(--primary-brand600);
  --active-text-color: var(--white);
  --active-outlined-bg-color: var(--light200);
  /* Focus Outline */
  --outline: 2px solid var(--light-accent200);
  --outline-offset: 2px;
  --outline-transition: outline-offset 300ms ease;
  /* error */
  --error-color: var(--danger-color);
  /* Disabled Colors */
  --disabled-color: var(--gray100);
  --disabled-text-color: var(--gray300);
  /* Borders */
  --border-color: var(--gray300);
  /* Box Shadow */
  --box-shadow: 0 1px 5px 0 rgba(217, 217, 217, 0.12),
    0 3px 1px 0 rgba(217, 217, 217, 0.12), 0 2px 2px 0 rgba(217, 217, 217, 0.12);
  /* Checkbox and Radio Size */
  --radio-checkbox-size: 1.5rem;
  --radio-checkbox-border: 1px solid var(--gray400);
  /* Cards */
  --card-bg-color: var(--dark200);
}

